@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');

*{
  padding: none;
  margin: none;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

.sidebar{
  width: 240px;
  height: 100vh;
  background-color: #001529;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.sidebart1
{
  color: white;
  font-weight: 500;
  font-size: 25px
}

.sidebart2
{
  color: white;
  font-weight: 300;
  font-size: 15px;
  margin-bottom: 20px;
}

.divider{
  width: 100%;
  height: 1px;
  background-color: white;
}

.sidebaroption{
  color: white;
  padding: 10px;
  cursor: pointer;
  border-radius: 20px;
}

.sidebaroption:hover{
  background-color: #28415a;

  
}

.sidebaroption > span{
  margin-left: 10px;
}

.pagedata{
  padding: 50px;
  display: flex;
  flex-direction: column;
}

.pagetitle{
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 40px;
}

.App{
  display: flex;
}

input[type=file]::file-selector-button {
  border: none;
  outline: none;
  padding: 10px;
  border-radius: .2em;
  background-color: indigo;
  color: white;
  cursor: pointer;
}

.pagedata  button{
  border: none;
  outline: none;
  padding: 10px;
  border-radius: .2em;
  background-color: indigo;
  color: white;
  cursor: pointer;
}

.navbar{
  width: calc(100vw - 240px);
  height: 60px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
}

.checker{
  margin-left: 15px;
}